// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/public/images/login_logo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../static/public/images/login_logo_mini.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".btn-primary{background-color:#369}html{font-size:14px}html,body,blockquote,code,h1,h2,h3,h4,h5,h6,p,pre{margin:0;padding:0}body{font-family:'Open Sans', HelveticaNeue, Helvetica, Arial, sans-serif;color:#000000}.appDiv{background-color:#FFF;min-width:1000px}.normalText{font-size:14px;color:#000000;line-height:16px}.loginPageLogo,.loginPageLogoMini{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:260px 194px;background-position:center;width:260px;height:260px}.loginPageLogoMini{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}textarea,input[type=text],input[type=password]{border-radius:4px;border:1px solid #CECECE;background-color:#fff}input[type=text],input[type=password]{box-shadow:none;height:25px}.loginForm{margin:auto;position:absolute;top:0;right:0;bottom:50%;left:0;height:250px;width:260px;max-width:95%}.loginForm input{height:32px;margin-bottom:20px}.loginError{color:red;text-align:center;padding-bottom:1em}\n", ""]);
// Exports
module.exports = exports;
