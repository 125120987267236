// WARNING this file is imported both from frontend and backend.
// Do not import frontend or backend files here.

import _ from 'lodash';
import moment from 'moment-timezone';
import { formatCurrency, isValue } from './utils';

const TIMEZONE = 'Europe/Helsinki';

export const paymentPropertyTranslationKeys = {
  accountDate: 'ACCOUNT_DATE',
  amount: 'AMOUNT',
  dateOfEntry: 'DATE_OF_ENTRY',
  message: 'MESSAGE',
  name: 'NAME',
  paymentDate: 'PAYMENT_DATE',
  paymentId: 'ID',
  reference: 'REFERENCE',
  type: 'TYPE',
};

export const customerPropertyTranslationKeys = {
  careOf: 'CARE_OF',
  city: 'CITY',
  firstName: 'FIRST_NAME',
  name: 'NAME',
  phone: 'PHONE_NUMBER',
  postcode: 'POSTCODE',
  streetAddress: 'STREET_ADDRESS',
  isForeign: 'FOREIGN_ADDRESS',
  identifier: 'IDENTIFIER',
};

const nonPrintableCustomerProperties = [
  'id',
  'identifier', // Handled separately for people and companies
];

export function formatCustomerProperty(key, value) {
  if (!_.has(customerPropertyTranslationKeys, key)) {
    return null;
  }

  if (_.includes(nonPrintableCustomerProperties, key)) {
    return null;
  }

  if (_.isNil(value) || value === '') {
    return null;
  }

  return value;
}

function formatValueChange(formattedPreviousValue, formattedUpdatedValue, translationKey) {
  if (isValue(formattedUpdatedValue) && !isValue(formattedPreviousValue)) {
    return ['B_ADDED_VALUE', translationKey, formattedUpdatedValue];
  }

  if (isValue(formattedPreviousValue) && !isValue(formattedUpdatedValue)) {
    return ['B_REMOVED_VALUE', translationKey, formattedPreviousValue];
  }

  if (
    isValue(formattedPreviousValue) &&
    isValue(formattedUpdatedValue) &&
    !_.isEqual(formattedPreviousValue, formattedUpdatedValue)
  ) {
    return ['B_EDITED_VALUE', translationKey, formattedPreviousValue, formattedUpdatedValue];
  }
}

export function formatCustomerValueChanges(
  previousValues,
  updatedValues,
  translationKeys = customerPropertyTranslationKeys,
  formatChange = formatValueChange,
) {
  const keys = _.union(_.keys(previousValues), _.keys(updatedValues));
  let descriptions = [];

  for (const key of keys) {
    const translationKey = _.get(translationKeys, key);
    const previousValue = _.get(previousValues, key);
    const updatedValue = _.get(updatedValues, key);

    if (translationKey) {
      const formattedPreviousValue = formatCustomerProperty(key, previousValue);
      const formattedUpdatedValue = formatCustomerProperty(key, updatedValue);
      const valueChangeDescription = formatChange(formattedPreviousValue, formattedUpdatedValue, translationKey);

      if (valueChangeDescription) {
        descriptions = [...descriptions, valueChangeDescription];
      }
    }
  }

  return descriptions;
}

export const claimPropertyTranslationKeys = {
  accountNumber: 'ACCOUNT_NUMBER',
  city: 'CITY',
  claimDate: 'CLAIM_DATE',
  claimMessage: 'CONTENT',
  comment: 'COMMENT',
  courtAppealDate: 'APPEAL',
  courtDecider: 'DECIDER',
  courtDecision: 'court_decision',
  courtDecisionDate: 'DECISION_DATE',
  courtDecisionExplanation: 'COURT_DECISION_EXPLANATION',
  courtDecisionReceivedDate: 'RECEIVED_DATE_COURT',
  courtResponseDate: 'RESPONSE',
  customer: {
    careOf: 'CARE_OF',
    city: 'CUSTOMER_CITY',
    firstName: 'CUSTOMER_FIRST_NAME',
    name: 'CUSTOMER_NAME',
    phone: 'CUSTOMER_PHONE',
    postcode: 'CUSTOMER_POSTCODE',
    streetAddress: 'CUSTOMER_ADDRESS',
    identifier: 'CUSTOMER_IDENTIFIER',
  },
  customerLanguage: 'LANGUAGE',
  dateOfBirth: 'DATE_OF_BIRTH',
  decider: 'DECIDER',
  decision: 'DECISION',
  decisionDate: 'DECISION_DATE',
  decisionExplanations: 'DECISION_EXPLANATION',
  decisionProposal: 'DECISION_PROPOSAL',
  decisionProposalExplanations: 'DECISION_PROPOSAL_EXPLANATION',
  decisionReceivedDate: 'RECEIVED_DATE',
  decisionSentDate: 'SENT_DATE',
  firstName: 'FIRST_NAME',
  gatheredInformation: 'CLAIM_GATHERED_INFORMATION',
  inspectorComment: 'INSPECTOR_COMMENT',
  lastName: 'LAST_NAME',
  phoneNumber: 'PHONE_NUMBER',
  postcode: 'POSTCODE',
  statement: 'STATEMENT',
  statementDate: 'STATEMENT_DATE',
  status: 'STATUS',
  streetAddress: 'STREET_ADDRESS',
  waitingForComment: 'WAITING_FOR_COMMENT',
};

const nonPrintableClaimProperties = ['id', 'reportId', 'madeFromCustomerUI', 'decisionId'];

export function formatClaimProperty(key, value) {
  if (!_.has(claimPropertyTranslationKeys, key)) {
    return null;
  }

  if (_.includes(nonPrintableClaimProperties, key)) {
    return null;
  }

  if (_.isNil(value) || value === '') {
    return null;
  }

  if (key.toLowerCase().indexOf('date') >= 0) {
    return moment.tz(value, TIMEZONE).format('DD.MM.YYYY');
  }

  if (key === 'decisionExplanations' || key === 'decisionProposalExplanations') {
    return _.flatMapDeep(value, (obj) => [obj.text, obj.legalcodes])
      .filter(_.identity)
      .join(' | ');
  }

  return value;
}

function formatClaimValueChange(formattedPreviousValue, formattedUpdatedValue, translationKey) {
  if (isValue(formattedUpdatedValue) && !isValue(formattedPreviousValue)) {
    return ['B_CLAIM_ADDED_VALUE', translationKey, formattedUpdatedValue];
  }

  if (isValue(formattedPreviousValue) && !isValue(formattedUpdatedValue)) {
    return ['B_CLAIM_REMOVED_VALUE', translationKey, formattedPreviousValue];
  }

  if (
    isValue(formattedPreviousValue) &&
    isValue(formattedUpdatedValue) &&
    !_.isEqual(formattedPreviousValue, formattedUpdatedValue)
  ) {
    return ['B_CLAIM_EDITED_VALUE', translationKey, formattedPreviousValue, formattedUpdatedValue];
  }
}

export function formatClaimValueChanges(previousValues, updatedValues, translationKeys = claimPropertyTranslationKeys) {
  const keys = _.union(_.keys(previousValues), _.keys(updatedValues));
  let descriptions = [];

  for (const key of keys) {
    const translationKey = _.get(translationKeys, key);
    const previousValue = _.get(previousValues, key);
    const updatedValue = _.get(updatedValues, key);

    if (key === 'customer') {
      const valueChangeDescriptions = formatCustomerValueChanges(
        previousValue,
        updatedValue,
        claimPropertyTranslationKeys.customer,
        formatClaimValueChange,
      );

      descriptions = [...descriptions, ...valueChangeDescriptions];
    } else if (translationKey) {
      const formattedPreviousValue = formatClaimProperty(key, previousValue);
      const formattedUpdatedValue = formatClaimProperty(key, updatedValue);
      const valueChangeDescription = formatClaimValueChange(
        formattedPreviousValue,
        formattedUpdatedValue,
        translationKey,
      );

      if (valueChangeDescription) {
        descriptions = [...descriptions, valueChangeDescription];
      }
    }
  }

  return descriptions;
}

export const reportPropertyTranslationKeys = {
  $timeOfDay: 'INCIDENT_TIME',
  addressAddition: 'STREET_ADDRESS_ADDITION',
  countryCode: 'COUNTRY_CODE',
  customerType: 'CUSTOMER_TYPE',
  demandDueDate: 'DEMAND_DUE_DATE',
  dueDate: 'DUE_DATE',
  extraInformation: 'EXTRA_INFORMATION',
  finishReason: 'FINISH_REASON',
  inspector: 'INSPECTOR',
  passiveReason: 'PASSIVE_REASON',
  paymentCents: 'TICKET_PAYMENT_AMOUNT',
  paymentCentsTotal: 'AMOUNT_TOTAL',
  paymentRaiseCents: 'PAYMENT_RAISE',
  plate: 'PLATE_LABEL',
  printedExtraInformation: 'PRINTED_EXTRA_INFORMATION',
  referenceNumber: 'REFERENCE',
  state: 'STATE',
  streetAddress: 'STREET_ADDRESS',
  ticketId: 'TICKET_ID',
  timestamp: 'INCIDENT_DATE',
  unclearReason: 'UNCLEAR_REASON',
  vehicleBrand: 'BRAND',
  vehicleColor: 'COLOR',
  vehicleModel: 'MODEL',
  vehicleType: 'VEHICLE_TYPE',
  customer: {
    careOf: 'CARE_OF',
    city: 'CUSTOMER_CITY',
    firstName: 'CUSTOMER_FIRST_NAME',
    name: 'CUSTOMER_NAME',
    phone: 'CUSTOMER_PHONE',
    postcode: 'CUSTOMER_POSTCODE',
    streetAddress: 'CUSTOMER_ADDRESS',
    identifier: 'CUSTOMER_IDENTIFIER',
  },
};

const nonPrintableReportProperties = [
  'id',
  'customerId',
  'deviceId',
  'incapabilityCertificateId',
  'location',
  'reasons',
  'unclearReasons',
  'userId',
];

export function formatReportProperty(key, value) {
  if (!_.has(reportPropertyTranslationKeys, key)) {
    return null;
  }

  if (_.includes(nonPrintableReportProperties, key)) {
    return null;
  }

  if (key === 'plate') {
    // Treat all plates as if they were Finnish, because we don't know the country code here
    return _.isString(value) && !_.isEmpty(value) ? value.match(/\d+|\D+/g).join('-') : value;
  }

  if (_.isNil(value) || value === '') {
    return null;
  }

  if (_.includes(['paymentCents', 'paymentRaiseCents'], key)) {
    return `${formatCurrency(value)} €`;
  }

  if (_.includes(['timestamp', 'observationStartTime'], key)) {
    return moment.tz(value, TIMEZONE).format('DD.MM.YYYY HH.mm');
  }

  if (_.includes(['dueDate', 'demandDueDate'], key)) {
    return moment.tz(value, TIMEZONE).format('DD.MM.YYYY');
  }

  return value;
}

export function formatArrayValueChanges(previousValues, updatedValues, removedTranslationKey, addedTranslationKey) {
  let descriptions = [];

  if (_.isArray(updatedValues)) {
    for (const updatedValue of updatedValues) {
      const isNew = !_.some(previousValues, (previousValue) => _.isEqual(updatedValue, previousValue));

      if (isNew) {
        descriptions = [...descriptions, [addedTranslationKey, updatedValue]];
      }
    }
  }

  if (_.isArray(previousValues)) {
    for (const previousValue of previousValues) {
      const wasRemoved = !_.some(updatedValues, (updatedValue) => _.isEqual(previousValue, updatedValue));

      if (wasRemoved) {
        descriptions = [...descriptions, [removedTranslationKey, previousValue]];
      }
    }
  }

  return descriptions;
}

export function formatReportValueChanges(
  previousValues,
  updatedValues,
  translationKeys = reportPropertyTranslationKeys,
) {
  const keys = _.union(_.keys(previousValues), _.keys(updatedValues));
  let descriptions = [];

  for (const key of keys) {
    const translationKey = _.get(translationKeys, key);
    const previousValue = _.get(previousValues, key);
    const updatedValue = _.get(updatedValues, key);

    if (key === 'reasons') {
      const changeTranslationKeys = ['B_REMOVED_REASON', 'B_ADDED_REASON'];
      const valueChangeDescriptions = formatArrayValueChanges(previousValue, updatedValue, ...changeTranslationKeys);
      descriptions = [...descriptions, ...valueChangeDescriptions];
    } else if (key === 'unclearReasons') {
      const changeTranslationKeys = ['B_REMOVED_UNCLEAR_REASON', 'B_ADDED_UNCLEAR_REASON'];
      const valueChangeDescriptions = formatArrayValueChanges(previousValue, updatedValue, ...changeTranslationKeys);
      descriptions = [...descriptions, ...valueChangeDescriptions];
    } else if (key === 'customer') {
      const valueChangeDescriptions = formatCustomerValueChanges(
        previousValue,
        updatedValue,
        reportPropertyTranslationKeys.customer,
      );
      descriptions = [...descriptions, ...valueChangeDescriptions];
    } else if (key === 'claim') {
      // for backwards compatibility
      const valueChangeDescriptions = formatClaimValueChanges(previousValue, updatedValue);
      descriptions = [...descriptions, ...valueChangeDescriptions];
    } else if (key === 'claims') {
      const changeTranslationKeys = ['B_REMOVED_CLAIM', 'B_ADDED_CLAIM'];
      const arrayValueChangeDescriptions = formatArrayValueChanges(
        previousValue,
        updatedValue,
        ...changeTranslationKeys,
      );
      descriptions = [...descriptions, ...arrayValueChangeDescriptions];

      if (_.isArray(previousValue) && _.isArray(updatedValue)) {
        for (const updatedClaim of updatedValue) {
          const previousClaim = _.find(previousValue, (claim) => claim.id === updatedClaim.id);

          if (previousClaim) {
            const valueChangeDescriptions = formatClaimValueChanges(previousClaim, updatedClaim);
            descriptions = [...descriptions, ...valueChangeDescriptions];
          }
        }
      }
    } else if (translationKey) {
      const formattedPreviousValue = formatReportProperty(key, previousValue);
      const formattedUpdatedValue = formatReportProperty(key, updatedValue);
      const valueChangeDescription = formatValueChange(formattedPreviousValue, formattedUpdatedValue, translationKey);

      if (valueChangeDescription) {
        descriptions = [...descriptions, valueChangeDescription];
      }
    }
  }

  return descriptions;
}
