import _ from 'lodash';
import moment from 'moment';
import {
  reportPropertyTranslationKeys,
  claimPropertyTranslationKeys,
  paymentPropertyTranslationKeys,
  customerPropertyTranslationKeys,
} from '../../backend/shared/model-format-utils';

const SUBSTITUTION_MARKER = '{}';

export const translation = {};
translation.translations = {};
translation.fallbackLanguage = 'fi';

function getTranslationFile(language) {
  return require(`./locale/${language}.json`);
}

function mapKeysToTranslations(propertyTranslationKeys, keyPrefix = '') {
  for (const key of _.keys(propertyTranslationKeys)) {
    const value = propertyTranslationKeys[key];

    if (_.isObject(value)) {
      mapKeysToTranslations(value, `${keyPrefix}${key}.`);
    } else {
      translation.translations[`${keyPrefix}${key}`] = translation.translations[value];
    }
  }
}

translation.initLanguage = function (language, fallbackLanguage) {
  translation.translations = getTranslationFile(language);
  translation.lang = language;
  translation.fallbackLanguage = fallbackLanguage;
  moment.locale(language);

  // Currency is now hardcoded to euros. Do here currency handling in the future.
  translation.currency = '€';

  translation.dateFormat = 'L';
  translation.inputDateFormats = ['L', 'l', 'ddd L', 'ddd l'];
  translation.dateTimeFormat = 'L HH:mm';
  translation.monthYearFormat = 'MMMM YYYY';
  translation.weekdays = moment.weekdaysShort();

  mapKeysToTranslations(reportPropertyTranslationKeys);
  mapKeysToTranslations(claimPropertyTranslationKeys);
  mapKeysToTranslations(paymentPropertyTranslationKeys);
  mapKeysToTranslations(customerPropertyTranslationKeys);
};

export function camelToUpperUnderscore(str) {
  return _.toUpper(_.replace(str, /[A-Z]+/g, (substr) => `_${substr}`));
}

function trStringId(strId) {
  return translation.translations[strId] || translation.translations[camelToUpperUnderscore(strId)] || strId;
}

// Return translation of { 'fi': 'EerikinKatu', 'sv': 'Erik´s Gatan' } type of objects.
export function _trObject(obj, language = translation.lang, fallbackLang = translation.fallbackLanguage) {
  if (!_.isObject(obj)) {
    return '';
  }

  const preferred = _.get(obj, language);
  const fallback = _.get(obj, fallbackLang);
  const any = _.values(obj).pop();

  return preferred || fallback || any || '';
}

/**
 * Attempts to translate pretty much anything.
 * Subtitution example:  _tr(['This is {} text {}.'], 'nice', 'row']) -> 'This is nice text row.'
 * @param {array, string} obj, first element is the text template into which the following elements are merged.
 * if parameter is a string, it is translated like a standard string.
 * if parameter is an object, it is translated as object containing translation.
 * if parameter is an array of objects, it each element is translated recursively
 * @param {string} fieldName, Field name of the translation object. Default is "text".
 * @ret {string} translated text
 */
export function _tr(obj, fieldName = 'text') {
  if (_.isString(obj) || _.isFinite(obj)) {
    return trStringId(obj);
  }

  if (_.isBoolean(obj)) {
    return obj ? _tr('YES') : _tr('NO');
  }

  if (_.isArray(obj) && obj.length > 0) {
    if (_.isString(obj[0])) {
      let text = _tr(obj[0]);

      let pos = 1;

      while (obj.length > pos && text.indexOf(SUBSTITUTION_MARKER) >= 0) {
        text = text.replace(SUBSTITUTION_MARKER, _tr(obj[pos]));
        pos++;
      }

      // remove remaining substitution markers
      text = text.replace(new RegExp(SUBSTITUTION_MARKER, 'g'), '');
      // remove empty brackets
      text = text.replace(/\(\)/g, '');

      return text;
    }
    return '';
  }

  if (_.isObject(obj)) {
    // format is {id: 'myid', [fieldName]: ... }
    if (_.has(obj, fieldName)) {
      const field = _.get(obj, fieldName);
      if (_.isObject(field)) {
        // format is [fieldName]: {fi: 'koira', en: 'dog'}}
        return _trObject(field);
      }

      if (_.isString(field)) {
        // format is [fieldName]: 'text'}
        return trStringId(field);
      }
    }

    // format is {fi: 'koira', en: 'dog'}
    return _trObject(obj);
  }

  return '';
}
