import _ from 'lodash';
import Promise from 'bluebird';
import StackTraceGPS from 'stacktrace-gps';
import ErrorStackParser from 'error-stack-parser';
import StackFrame from 'stackframe';
import request from './request';
import { optionalAsync } from '../../backend/shared/utils';
import { _tr } from './translate.js';

const stackTraceGps = new StackTraceGPS();

export const ErrorLevel = {
  Error: 'ERROR',
};

async function remoteLog({ message, stack, level, data }) {
  await request.post('/remote-log', { message, stack, level, data });
}

async function mapStackFrame(stackFrame) {
  try {
    if (!stackFrame.lineNumber) {
      return `at ${stackFrame.functionName}`;
    }

    const pinpoint = await stackTraceGps.pinpoint(stackFrame);
    const filename = pinpoint.fileName.replace('webpack:///', '');
    const location = [filename, pinpoint.lineNumber, pinpoint.columnNumber].filter(_.identity).join(':');

    return `at ${stackFrame.functionName} (${location})`;
  } catch (parseError) {
    return `(failed to parse stack frame ${stackFrame}: ${parseError.stack})`;
  }
}

async function parseStackTrace(error) {
  try {
    const originalStackTrace = ErrorStackParser.parse(error);

    const mappedFrames = await Promise.map(originalStackTrace, mapStackFrame);
    return mappedFrames.join('\n');
  } catch (parseError) {
    return `(failed to parse stack ${error.stack}: ${parseError.stack})`;
  }
}

/**
 * Logs a message and/or an exception only remotely.
 */
export function logErrorSilent(...args) {
  return optionalAsync(async () => {
    let message;
    let stack;
    let originalError;

    if (_.isError(args[0])) {
      originalError = args[0];
    } else {
      message = args[0];
      originalError = args[1];
    }

    if (_.isError(originalError)) {
      const mappedStackTrace = await parseStackTrace(originalError);
      stack = `${originalError}\n${mappedStackTrace}`;
    }

    await remoteLog({ message, stack, level: ErrorLevel.Error, data: originalError ? originalError.data : undefined });
  })();
}

/**
 * Logs a message and/or an exception both locally and remotely.
 */
export function logError(...args) {
  console.error(...args);
  return logErrorSilent(...args);
}

/**
 * Enables remote error logging for all uncaught exceptions and unhandled promise rejections.
 */
export function setupGlobalErrorHandlers() {
  window.onerror = function (message, filename, lineNumber, columnNumber, error) {
    (async () => {
      try {
        if (_.isError(error)) {
          await logErrorSilent(error);
        } else {
          // Fallback for browsers that do not support the error parameter
          const stackFrame = new StackFrame('unknown', [], filename, lineNumber, columnNumber);
          const mappedStackFrame = await mapStackFrame(stackFrame);
          await remoteLog({ message, stack: mappedStackFrame, level: ErrorLevel.Error });
        }
      } catch (ignored) {
        // ignored to avoid infinite loop if remote logging fails
      }
    })();
  };

  window.onunhandledrejection = function ({ reason }) {
    logError('Unhandled promise rejection', reason);
  };
}

export function handleRequestError(err, customStatusHandler) {
  const status = _.get(err, 'response.status');
  const data = _.get(err, 'response.data.data', {});

  if (status && _.isFunction(customStatusHandler)) {
    const result = customStatusHandler(status, data);

    if (_.isString(result)) {
      return {
        errorText: result,
        errorHandled: true,
        errorField: null,
      };
    }
    if (_.isObject(result)) {
      return {
        errorHandled: true,
        errorField: null,
        ...result,
      };
    }
  }

  if (_.has(err, 'response.data.uuid')) {
    return {
      errorText: _tr('SERVER_ERROR'),
      errorHandled: false,
      errorField: null,
    };
  }

  return {
    errorText: _tr('NETWORK_ERROR'),
    errorHandled: false,
    errorField: null,
  };
}
