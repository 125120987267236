import _ from 'lodash';
import { store } from '../store.js';
import { logError } from '../errors';
import { CHANGE_USER, LOGOUT } from './action-types.js';
import { translation } from '../translate';

export function changeUser(user) {
  return async (dispatch) => {
    const userString = user ? encodeURIComponent(JSON.stringify(user)) : null;
    document.cookie = `user=${userString};path=/`;
    const language = user ? user.language : _.get(store.getState().settingsReducer, 'ticketDetails.languages[0]');

    try {
      await translation.initLanguage(language);
    } catch (err) {
      logError(`Failed to initialize language ${language}`, err);
    }

    dispatch({
      type: CHANGE_USER,
      user,
    });
  };
}

export function logout() {
  document.cookie = 'X-Auth-Token=null;path=/';
  window.location = '/login';

  return { type: LOGOUT };
}

export function handleLogout(err) {
  if (_.get(err, 'response.status') === 401) {
    // Logout user as session has been expired
    store.dispatch(logout());
  }
}
