import 'babel-polyfill';
import axios from 'axios';
import qs from 'qs';
import { handleLogout } from './actions/authentication-actions';
import { omitPropertiesStartingWith } from './util';
import { ApiVersion } from '../../backend/shared';

const client = axios.create({
  baseURL: `/api/${ApiVersion}/`,
  transformRequest: [(data) => omitPropertiesStartingWith(data, '$'), ...axios.defaults.transformRequest],
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    handleLogout(error);

    throw error;
  },
);

export default client;
