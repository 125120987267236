import _ from 'lodash';
import axios from 'axios';
import { ApiVersion } from '../../../backend/shared';

const client = axios.create({
  baseURL: `/api/${ApiVersion}/`,
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (_.isError(error)) {
      throw error;
    }

    const httpError = new Error(`${error.status} ${error.statusText}`);
    Object.assign(httpError, error);
    throw httpError;
  },
);

export default client;
