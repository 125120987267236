// WARNING this file is imported both from frontend and backend.
// Do not import frontend or backend files here.

import _ from 'lodash';
import moment from 'moment-timezone';
import bluebird from 'bluebird';
import easter from './easter';
import {
  companyIdentifierPattern,
  personIdentifierPattern,
  postcodePattern,
  phoneNumberPattern,
  emailPattern,
  postcodeInCityFieldRegex,
} from '.';

export const TIMEZONE = 'Europe/Helsinki';

export const defaultDateBounds = {
  minDate: moment('2016-01-01'),
  maxDate: moment().endOf('year'),
};

export function addChecksumToReferenceNumber(referenceNumber) {
  const checkSum = String(referenceNumber)
    .replace(/[\s]/g, '')
    .split('')
    .reverse()
    .reduce((sum, digit, index) => {
      switch (index % 3) {
        case 0:
          return sum + digit * 7;
        case 1:
          return sum + digit * 3;
        case 2:
          return sum + digit * 1;
        default:
          return sum;
      }
    }, 0);

  const checkDigit = (10 - (checkSum % 10)) % 10;

  return `${referenceNumber}${checkDigit}`;
}

const companyIdentifierChecksumWeights = [7, 9, 10, 5, 8, 4, 2];

export function calculateCompanyIdentifierCheckDigit(_identifier) {
  let identifier = _identifier;

  if (_.includes(identifier, '-')) {
    identifier = identifier.replace(/-.*/, '');
  }

  identifier = _.padStart(identifier.slice(0, 7), 7, '0');

  const checksum = identifier
    .split('')
    .reduce((sum, digit, index) => sum + digit * companyIdentifierChecksumWeights[index], 0);

  const checkDigit = (11 - (checksum % 11)) % 11;

  if (checkDigit > 9) {
    throw new Error('Invalid company identifier');
  }

  return `${checkDigit}`;
}

const personIdentifierCheckDigits = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'P',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
];

export function calculatePersonIdentifierCheckDigit(identifier) {
  const numbers = `${identifier.slice(0, 6)}${identifier.slice(7, 10)}`;
  const modulo = parseInt(numbers, 10) % 31;

  return personIdentifierCheckDigits[modulo];
}

export function validateCompanyIdentifier(identifier) {
  if (!_.isString(identifier)) {
    return false;
  }

  if (identifier.search(companyIdentifierPattern) === -1) {
    return false;
  }

  try {
    return identifier.slice(-1) === calculateCompanyIdentifierCheckDigit(identifier);
  } catch (ignored) {
    return false;
  }
}

export function validatePersonIdentifier(identifier) {
  if (!_.isString(identifier)) {
    return false;
  }

  if (identifier.search(personIdentifierPattern) === -1) {
    return false;
  }

  return identifier.slice(-1) === calculatePersonIdentifierCheckDigit(identifier);
}

export function validateReferenceNumber(referenceNumber) {
  const refWithoutChecksum = referenceNumber.slice(0, -1);
  const validRef = addChecksumToReferenceNumber(refWithoutChecksum);
  return referenceNumber === validRef;
}

export function validatePostcode(postcode) {
  if (!_.isString(postcode)) {
    return false;
  }

  return postcode.search(postcodePattern) !== -1;
}

export function validatePhoneNumber(value) {
  if (!_.isString(value)) {
    return false;
  }

  return value.search(phoneNumberPattern) !== -1;
}

export function validateEmail(value) {
  if (!_.isString(value)) {
    return false;
  }

  return value.search(emailPattern) !== -1;
}

export function signMatch(a, b) {
  return (a >= 0 && b >= 0) || (a <= 0 && b <= 0);
}

export function isValue(val) {
  if (_.isString(val) && val.trim().length === 0) {
    return false;
  }

  if (_.isArray(val) && val.length === 0) {
    return false;
  }

  if (Number.isNaN(val)) {
    return false;
  }

  return !_.isNil(val);
}

export function valueOrNull(value) {
  return isValue(value) ? value : null;
}

/*
 * Takes cents and returns euros, with decimals if it's not an even euro amount or without if it is.
 * */
export function formatCurrency(cents, separator = '.', alwaysShowCents = false) {
  const euros = cents / 100;
  const showCents = alwaysShowCents || cents % 100 !== 0;
  return showCents ? euros.toFixed(2).replace('.', separator) : `${euros}`;
}

export function generateReferenceNumber(ticketId, paymentDetails) {
  if (!ticketId) {
    return '';
  }

  let referenceNumber = '';

  if (paymentDetails.referencePrefix) {
    referenceNumber += paymentDetails.referencePrefix;
  }

  referenceNumber += ticketId;

  if (paymentDetails.referenceSuffix) {
    referenceNumber += paymentDetails.referenceSuffix;
  }

  return addChecksumToReferenceNumber(referenceNumber);
}

export function formatReferenceNumber(_value) {
  if (_.isString(_value)) {
    const value = _value.replace(/\s/g, '');

    const isNumericString = /^\d+$/.test(value);
    if (isNumericString) {
      const offset = value.length % 5;
      const firstPart = value.slice(0, offset);
      const rest = value.slice(offset);
      const groups = rest.match(/.{5}/g);
      return _.compact(_.concat([firstPart], groups)).join(' ');
    }
  }

  return _value;
}

export function isCustomerIncapable(customer) {
  return (
    customer &&
    _.isArray(customer.incapabilityCertificates) &&
    _.some(customer.incapabilityCertificates, (item) => item.valid)
  );
}

export function getBankDayStartingFrom(dateString, additionalBankDays = 0) {
  const date = moment.tz(dateString, TIMEZONE).startOf('day');
  const newYearsDay = moment.tz('2016-01-01', TIMEZONE).year(date.year());
  const epiphany = moment.tz('2016-01-06', TIMEZONE).year(date.year());
  const easterSunday = moment.tz(easter(date.year()).format('YYYY-MM-DD'), TIMEZONE);
  const easterFriday = moment.tz(easterSunday, TIMEZONE).subtract(2, 'day');
  const easterMonday = moment.tz(easterSunday, TIMEZONE).add(1, 'day');
  const ascensionDay = moment.tz(easterSunday, TIMEZONE).add(39, 'day');
  const firstOfMay = moment.tz('2016-05-01', TIMEZONE).year(date.year());

  const getMidSummer = (year) => {
    const start = moment.tz('2016-06-20', TIMEZONE).year(year);
    const end = moment.tz('2016-06-26', TIMEZONE).year(year);

    for (const returnValue = moment(start); returnValue <= moment(end); returnValue.add(1, 'day')) {
      if (returnValue.isoWeekday() === 6) {
        return returnValue;
      }
    }
  };

  const midSummerEve = moment.tz(getMidSummer(date.year()), TIMEZONE).subtract(1, 'day');
  const independenceDay = moment.tz('2016-12-06', TIMEZONE).year(date.year());
  const christmasEve = moment.tz('2016-12-24', TIMEZONE).year(date.year());
  const firstChristmasDay = moment.tz('2016-12-25', TIMEZONE).year(date.year());
  const secondChristmasDay = moment.tz('2016-12-26', TIMEZONE).year(date.year());

  const bankHolidays = [
    newYearsDay,
    epiphany,
    easterFriday,
    easterMonday,
    ascensionDay,
    firstOfMay,
    midSummerEve,
    independenceDay,
    christmasEve,
    firstChristmasDay,
    secondChristmasDay,
  ];

  const isHoliday = (_date) => {
    return _date.isoWeekday() > 5 || _.some(bankHolidays, (item) => _date.isSame(item, 'day'));
  };

  let returnValue = date;
  let bankDays = 0;
  while (isHoliday(returnValue) || bankDays < additionalBankDays) {
    if (!isHoliday(returnValue)) {
      ++bankDays;
    }
    returnValue = returnValue.add(1, 'day');
  }

  return returnValue.format('YYYY-MM-DD');
}

/**
 * Wraps an asynchronous function into a synchronous function that returns a lazy thenable.
 * If the returned thenable is discarded, no unhandled promise rejection event will occur.
 * Useful for functions that handle the result and thrown errors internally,
 * while also providing the option for the caller to wait for the result or rejection.
 * @param asyncFunction
 * @returns {Function}
 */
export function optionalAsync(asyncFunction) {
  if (!_.isFunction(asyncFunction)) {
    throw new Error('Invalid parameters, expected a function');
  }

  return function (...args) {
    const pendingResult = bluebird.try(() => asyncFunction(...args)).reflect();

    return {
      then(onFulfilled, onRejected) {
        pendingResult.then((result) => {
          if (result.isRejected()) {
            onRejected(result.reason());
          } else {
            onFulfilled(result.value());
          }
        });
      },
    };
  };
}

export function formatCustomerName(customer) {
  return [customer.firstName, customer.name].filter(_.identity).join(' ');
}

export function formatCustomerNameWithIdentifier(customer) {
  const name = formatCustomerName(customer);

  if (customer.identifier) {
    return `${name} (${customer.identifier})`;
  }

  return name;
}

export function getPaymentComparisonDate(payment) {
  const { dateOfEntry, accountDate, paymentDate } = payment;
  return _.find([dateOfEntry, accountDate, paymentDate], (date) => !!date);
}

export function cityFieldContainsPostcode(customer) {
  return postcodeInCityFieldRegex.test(_.get(customer, 'city', ''));
}

export function cleanLocalizableObject(object) {
  if (!_.isPlainObject(object)) {
    return object;
  }

  return _.omit(object, ['default', 'sortWeight', 'editable']);
}

export function normalizeLicensePlate(licensePlate) {
  if (!_.isString(licensePlate) || !licensePlate) {
    return null;
  }

  return licensePlate.toUpperCase().replace(/-/g, '');
}

export function denormalizeLicensePlate(licensePlate, countryCode) {
  if (!_.isString(licensePlate) || !licensePlate) {
    return null;
  }

  if (!countryCode || countryCode.id === 'FIN') {
    const groups = licensePlate.toUpperCase().match(/\d+|\D+/g);
    return groups.join('-');
  }

  return licensePlate;
}
