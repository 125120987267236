import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Button } from 'react-bootstrap';
import humanizeDuration from 'humanize-duration';
import Promise from 'bluebird';
import _ from 'lodash';
import request from '../request-public';
import { _tr, translation } from '../translate-public.js';
import { logError } from '../../errors';

export default class LoginPage extends React.Component {
  static propTypes = {
    isMiniVersion: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      waitTime: null,
      waitTimeText: null,
      errorText: null,
      username: '',
      password: '',
    };

    this.setState = Promise.promisify(this.setState);
    this.intervalId = null;
  }

  @autobind
  async setWaitTimeInterval(_waitTime) {
    let waitTime = _waitTime;
    let waitTimeText = _tr(['LOGIN_ERROR_WAIT', humanizeDuration(waitTime, { language: translation.lang })]);
    await this.setState({ waitTime, waitTimeText });

    const interval = 1000;

    if (this.intervalId) clearInterval(this.intervalId);

    this.intervalId = setInterval(() => {
      waitTime = this.state.waitTime - interval;
      waitTimeText = _tr(['LOGIN_ERROR_WAIT', humanizeDuration(waitTime, { language: translation.lang })]);
      if (waitTime <= 0) {
        waitTime = null;
        waitTimeText = null;
        clearInterval(this.intervalId);
      }
      this.setState({ waitTime, waitTimeText });
    }, interval);
  }

  @autobind
  handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;
    this.setState({ [key]: value });
  }

  @autobind
  handleSubmit(event) {
    event.preventDefault();
    const username = this.state.username;
    const password = this.state.password;

    if (username && password) {
      this.login(username, password);
    }
  }

  @autobind
  async login(username, password) {
    const msg = { username, password };

    try {
      const { data } = await request.post('/login', msg);

      const userString = encodeURIComponent(JSON.stringify(data.user));

      document.cookie = `X-Auth-Token=${data.token};path=/`;
      document.cookie = `user=${userString};path=/`;
      window.location = '/';
    } catch (error) {
      let errorText = _tr('LOGIN_ERROR_GENERIC');
      const waitTime = _.get(error, 'response.data.data.waitTime');

      if (_.isFinite(waitTime)) {
        this.setWaitTimeInterval(waitTime);
      }

      if (error.status === 401 || error.status === 400) {
        errorText = _tr('LOGIN_ERROR_INCORRECT');
      } else if (error.status === 429) {
        errorText = _tr('LOGIN_ERROR_THROTTLED');
      }
      this.setState({ errorText });

      if (!_.includes([401, 429], error.status)) {
        logError('Failed to login', error);
      }
    }
  }

  render() {
    return (
      <div className="appDiv">
        <form onSubmit={this.handleSubmit} className="normalText loginForm">
          <div className={this.props.isMiniVersion ? 'loginPageLogoMini' : 'loginPageLogo'} />
          <label htmlFor="name">{_tr('USERNAME')}</label>
          <input
            className="form-control"
            type="text"
            name="username"
            placeholder={_tr('USERNAME')}
            value={this.state.username}
            onChange={this.handleChange}
            autoFocus
          />
          <label htmlFor="name">{_tr('PASSWORD')}</label>
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder={_tr('PASSWORD')}
            onChange={this.handleChange}
          />
          {this.state.errorText ? <div className="loginError">{this.state.errorText}</div> : null}
          {this.state.waitTimeText ? <div className="loginError">{this.state.waitTimeText}</div> : null}
          <Button className="btn btn-primary btn-lg btn-block" type="submit">
            {_tr('LOGIN')}
          </Button>
        </form>
      </div>
    );
  }
}
