import _ from 'lodash';
import moment from 'moment';
import { store } from './store.js';
import { Permission as _permission, ComputedReportState } from '../../backend/shared';
import { isValue as _isValue, defaultDateBounds } from '../../backend/shared/utils';
import { _tr } from './translate';

export const DEBOUNCE_DELAY_MS = 600;

export const isValue = _isValue;
export const Permission = _permission;
export const cols = {
  full: `${100}%`,
  half: `${100 / 2}%`,
  fourth: `${100 / 4}%`,
  sixth: `${100 / 6}%`,
  third: `${100 / 3}%`,
  twoThirds: `${100 / 1.5}%`,
  fiveSixths: `${500 / 6}%`,
};

export function compareIdNumerical(a, b) {
  return parseInt(a.id, 10) - parseInt(b.id, 10);
}

export function comparePredefinedOrder(a, b, orderArray) {
  const aIdx = orderArray.indexOf(a);
  const bIdx = orderArray.indexOf(b);
  return (aIdx >= 0 ? aIdx : Number.MAX_SAFE_INTEGER) - (bIdx >= 0 ? bIdx : Number.MAX_SAFE_INTEGER);
}

export function compareTranslationsAlphabetically(a, b) {
  const aText = _tr(a).toLowerCase();
  const bText = _tr(b).toLowerCase();
  if (aText < bText) return -1;
  if (aText > bText) return 1;
  return 0;
}

export function initDefaultMinDate() {
  const firstTimestampString = store.getState().settingsReducer.settings.firstReportTimestamp;
  const firstTimestamp = firstTimestampString ? moment(firstTimestampString) : null;
  if (firstTimestamp && moment(firstTimestamp).isValid()) {
    defaultDateBounds.minDate = firstTimestamp.startOf('year');
  }
}

export function constrainDateFilter(value, bounds, defaultDate) {
  const dateVal = moment(value, moment.ISO_8601);
  if (!dateVal.isValid()) {
    return defaultDate;
  }

  const { minDate, maxDate } = bounds;

  if (!_.isNil(minDate) && dateVal.isBefore(minDate)) {
    return minDate.toISOString();
  }

  if (!_.isNil(maxDate) && dateVal.isAfter(maxDate)) {
    return maxDate.toISOString();
  }
  return value;
}

export function changeFilter(name, _value, _filter, dateBounds = defaultDateBounds) {
  let value = typeof _value === 'number' && !_.isFinite(_value) ? undefined : _value;

  const isDateChange = _.some(['startDate', 'endDate'], (field) => _.toLower(name).includes(_.toLower(field)));

  if (isDateChange && !_.isNil(dateBounds) && _value !== '') {
    // Can't manually write date before minDate or after maxDate
    value = constrainDateFilter(value, dateBounds, _.get(_filter, name));
  }

  const filter = _.cloneDeep(_filter);
  _.set(filter, name, value);

  return filter;
}

export function compareSortWeights(a, b) {
  const aWeight = _.isFinite(_.get(a, 'sortWeight')) ? a.sortWeight : Number.MAX_SAFE_INTEGER;
  const bWeight = _.isFinite(_.get(b, 'sortWeight')) ? b.sortWeight : Number.MAX_SAFE_INTEGER;
  const weigthDifference = aWeight - bWeight;

  if (weigthDifference !== 0) return weigthDifference;

  return compareTranslationsAlphabetically(a, b);
}

export function scrollToId(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView();
  }
}

export function hasPermission(permission) {
  const userPermissions = _.get(store.getState().authenticationReducer, 'user.role.permissions');
  return _.includes(userPermissions, permission);
}

export function hasAnyPermission(...permissions) {
  const userPermissions = _.get(store.getState().authenticationReducer, 'user.role.permissions');
  return _.some(permissions, (permission) => _.includes(userPermissions, permission));
}

export function isOwnResource(resource) {
  const userId = _.get(store.getState().authenticationReducer, 'user.id');
  if (resource) {
    const idField = _.has(resource, 'roleId') ? 'id' : 'userId';
    return userId === resource[idField];
  }
  // if no resource is given, return true
  return true;
}

export function checkPermission(permission, resourceName, resource) {
  const own = `${permission}Own`;
  return (
    hasPermission(Permission[resourceName][permission]) ||
    (hasPermission(Permission[resourceName][own]) && isOwnResource(resource))
  );
}

export function checkDeletePermission(resourceName, resource) {
  return checkPermission('Delete', resourceName, resource);
}

export function checkReadPermission(resourceName, resource) {
  return checkPermission('Read', resourceName, resource);
}

export function checkUpdatePermission(resourceName, resource) {
  return checkPermission('Update', resourceName, resource);
}

export function checkCreatePermission(resourceName) {
  return checkPermission('Create', resourceName);
}

export function formatFileSize(bytes) {
  if (bytes === 0) return '0 B';
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}

export function isTranslationObjectEmpty(val) {
  for (const lang in val) {
    if (Object.prototype.hasOwnProperty.call(val, lang) && isValue(val[lang])) {
      return false;
    }
  }
  return true;
}

// Remove empty values from params
export function cleanParams(params) {
  const result = {};
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && isValue(params[key])) {
      let value = params[key];
      if (key === 'search') {
        value = value.replace(/_/g, '\\_').replace(/%/g, '\\%').replace(/\*+/g, '%');
        if (!value.endsWith('%') || value.endsWith('\\%')) {
          value = `${value}%`;
        }
      }
      result[key] = value;
    }
  }
  return result;
}

export function omitPropertiesStartingWith(object, prefix) {
  if (_.isPlainObject(object)) {
    return _.transform(object, (result, value, key) => {
      if (!_.startsWith(key, prefix)) {
        result[key] = omitPropertiesStartingWith(value, prefix);
      }

      return result;
    });
  }

  if (_.isArray(object)) {
    return _.map(object, (value) => omitPropertiesStartingWith(value, prefix));
  }

  return object;
}

// Transforms an object with special values that have no JSON representation into a safe object that can be stringified with JSON.stringify.
function makeSafeObject(object) {
  if (_.isPlainObject(object)) {
    const unorderedObject = _.mapValues(object, (value) => {
      if (value === undefined) {
        return 'undefined';
      }
      if (_.isDate(value)) {
        return `Date ${value.toISOString()}`;
      }
      if (_.isNaN(value)) {
        return 'NaN';
      }
      if (value === Infinity) {
        return 'Infinity';
      }
      if (value === -Infinity) {
        return '-Infinity';
      }
      if (_.isString(value)) {
        // double-stringify strings so we can safely represent special values as strings
        return JSON.stringify(value);
      }

      return makeSafeObject(value);
    });

    // Object property iteration order is not guaranteed, but in practice browsers use the definition order so we can do this:
    return _.keys(unorderedObject)
      .sort()
      .reduce((orderedObject, key) => {
        orderedObject[key] = unorderedObject[key];
        return orderedObject;
      }, {});
  }

  if (_.isArray(object)) {
    return _.map(object, (value) => makeSafeObject(value));
  }

  return object;
}

// Extends JSON.stringify to handle some special values that have no JSON representation.
export function safeStringify(object) {
  if (object === undefined) {
    return 'undefined';
  }

  return JSON.stringify(makeSafeObject(object));
}

// Used to recover the special values and strings in an object transformed with safeStringify.
function reviveSafeObject(object) {
  if (_.isPlainObject(object)) {
    return _.mapValues(object, (value) => {
      if (value === 'undefined') {
        return undefined;
      }
      if (_.startsWith(value, 'Date ')) {
        return new Date(value.slice(5));
      }
      if (value === 'NaN') {
        return NaN;
      }
      if (value === 'Infinity') {
        return Infinity;
      }
      if (value === '-Infinity') {
        return -Infinity;
      }
      if (_.isString(value)) {
        // parse double-stringified string
        return JSON.parse(value);
      }

      return reviveSafeObject(value);
    });
  }

  if (_.isArray(object)) {
    return _.map(object, (value) => reviveSafeObject(value));
  }

  return object;
}

// Extends JSON.parse to handle some special values that have no JSON representation.
export function safeParse(string) {
  if (string === undefined || string === 'undefined') {
    return undefined;
  }

  return reviveSafeObject(JSON.parse(string));
}

export function toUpperCase(value) {
  return _.isString(value) ? value.toUpperCase() : value;
}

export function formatIBAN(_value) {
  if (_.isString(_value)) {
    const value = _value.replace(/\s/g, '');

    if (value.length === 0) return '';

    const groups = value.match(/.{1,4}/g);
    return groups.join(' ').toUpperCase();
  }

  return _value;
}

// Capitalize first letter
export function capitalize(string) {
  if (!_.isString(string)) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Juha-Pekka, Juha Pekka
export function capitalizeName(string) {
  if (!_.isString(string)) return string;

  return string.replace(/[\w\u00C0-\u017F][^\s-]*/g, (txt) => txt.charAt(0).toUpperCase() + _.toLower(txt.substr(1)));
}

export function formatTicketState(ticket, state = _.get(ticket, 'state')) {
  const isUnclear = !_.isEmpty(_.get(ticket, 'unclearReasons'));

  return isUnclear ? `${_tr(state)} (${_tr(ComputedReportState.UNCLEAR)})` : _tr(state);
}

export function getIfUnmodifiedSinceHeaders(lastModified) {
  if (!lastModified) {
    return {};
  }

  const lastModifiedDate = moment(lastModified);

  if (!lastModifiedDate.isValid()) {
    throw new Error(`Date ${lastModifiedDate} is not valid`);
  }

  return {
    'If-Unmodified-Since': lastModifiedDate.toDate().toUTCString(),
  };
}
