export const CHANGE_USER = 'CHANGE_USER';
export const LOGIN = 'LOGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const SET_RECTIFICATION_CLAIM_FILTERS = 'SET_RECTIFICATION_CLAIM_FILTERS';
export const FETCH_RECTIFICATION_CLAIMS = 'FETCH_RECTIFICATION_CLAIMS';
export const FETCH_RECTIFICATION_CLAIMS_SUCCESS = 'FETCH_RECTIFICATION_CLAIMS_SUCCESS';
export const FETCH_RECTIFICATION_CLAIMS_FAILURE = 'FETCH_RECTIFICATION_CLAIMS_FAILURE';

export const UPDATE_RECTIFICATION_CLAIM = 'UPDATE_RECTIFICATION_CLAIM';
export const UPDATE_RECTIFICATION_CLAIM_SUCCESS = 'UPDATE_RECTIFICATION_CLAIM_SUCCESS';
export const UPDATE_RECTIFICATION_CLAIM_FAILURE = 'UPDATE_RECTIFICATION_CLAIM_FAILURE';

export const SET_TICKET_FILTERS = 'SET_TICKET_FILTERS';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE';

export const FETCH_TICKET = 'FETCH_TICKET';
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export const FETCH_TICKET_FAILURE = 'FETCH_TICKET_FAILURE';

export const FETCH_ADJACENT = 'FETCH_ADJACENT';
export const FETCH_ADJACENT_SUCCESS = 'FETCH_ADJACENT_SUCCESS';
export const FETCH_ADJACENT_FAILURE = 'FETCH_ADJACENT_FAILURE';

export const SELECT_TICKET = 'SELECT_TICKET';

export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE';

export const ADD_TICKET = 'ADD_TICKET';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAILURE = 'ADD_TICKET_FAILURE';

export const FETCH_OTHER_TICKETS = 'FETCH_OTHER_TICKETS';
export const FETCH_OTHER_TICKETS_SUCCESS = 'FETCH_OTHER_TICKETS_SUCCESS';
export const FETCH_OTHER_TICKETS_FAILURE = 'FETCH_OTHER_TICKETS_FAILURE';

export const TOGGLE_SELECTED_FOR_RECOVERY = 'TOGGLE_SELECTED_FOR_RECOVERY';
export const SELECT_DESELECT_ALL_FOR_RECOVERY = 'SELECT_DESELECT_ALL_FOR_RECOVERY';
export const TOGGLE_MARKING_FOR_RECOVERY = 'TOGGLE_MARKING_FOR_RECOVERY';

export const FETCH_READY_FOR_RECOVERY_SUCCESS = 'FETCH_READY_FOR_RECOVERY_SUCCESS';
export const FETCH_READY_FOR_RECOVERY_FAILURE = 'FETCH_READY_FOR_RECOVERY_FAILURE';
export const FETCH_READY_FOR_RECOVERY = 'FETCH_READY_FOR_RECOVERY';

export const FETCH_VEHICLE_OWNERS = 'FETCH_VEHICLE_OWNERS';
export const FETCH_VEHICLE_OWNERS_SUCCESS = 'FETCH_VEHICLE_OWNERS_SUCCESS';
export const FETCH_VEHICLE_OWNERS_FAILURE = 'FETCH_VEHICLE_OWNERS_FAILURE';

export const FETCH_VEHICLE_INFORMATION = 'FETCH_VEHICLE_INFORMATION';
export const FETCH_VEHICLE_INFORMATION_SUCCESS = 'FETCH_VEHICLE_INFORMATION_SUCCESS';
export const FETCH_VEHICLE_INFORMATION_FAILURE = 'FETCH_VEHICLE_INFORMATION_FAILURE';

export const SET_PAYMENT_FILTERS = 'SET_PAYMENT_FILTERS';
export const RESET_PAYMENTS = 'RESET_PAYMENTS';
export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE';

export const LINK_PAYMENT = 'LINK_PAYMENT';
export const LINK_PAYMENT_SUCCESS = 'LINK_PAYMENT_SUCCESS';
export const LINK_PAYMENT_FAILURE = 'LINK_PAYMENT_FAILURE';

export const UNLINK_PAYMENT = 'UNLINK_PAYMENT';
export const UNLINK_PAYMENT_SUCCESS = 'UNLINK_PAYMENT_SUCCESS';
export const UNLINK_PAYMENT_FAILURE = 'UNLINK_PAYMENT_FAILURE';

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';

export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE';

export const ROUTE_LOCATION_UPDATED = 'ROUTE_LOCATION_UPDATED';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export const UPDATE_TICKET_REASONS = 'UPDATE_TICKET_REASONS';

export const ADD_NEW_TICKET_REASON = 'ADD_NEW_TICKET_REASON';
export const CANCEL_ADDING_NEW_TICKET_REASON = 'CANCEL_ADDING_NEW_TICKET_REASON';
export const ADD_NEW_TICKET_REASON_GROUP = 'ADD_NEW_TICKET_REASON_GROUP';
export const CANCEL_ADDING_NEW_TICKET_REASON_GROUP = 'CANCEL_ADDING_NEW_TICKET_REASON_GROUP';
export const SET_TICKET_REASON_GROUPS = 'SET_TICKET_REASON_GROUPS';

export const FETCH_PAYMENT_STATUS_SUMMARY = 'FETCH_PAYMENT_STATUS_SUMMARY';
export const FETCH_PAYMENT_STATUS_SUMMARY_SUCCESS = 'FETCH_PAYMENT_STATUS_SUMMARY_SUCCESS';
export const FETCH_PAYMENT_STATUS_SUMMARY_FAILURE = 'FETCH_PAYMENT_STATUS_SUMMARY_FAILURE';

export const FETCH_RECTIFICATION_CLAIM_STATUS_SUMMARY = 'FETCH_RECTIFICATION_CLAIM_STATUS_SUMMARY';
export const FETCH_RECTIFICATION_CLAIM_STATUS_SUMMARY_SUCCESS = 'FETCH_RECTIFICATION_CLAIM_STATUS_SUMMARY_SUCCESS';
export const FETCH_RECTIFICATION_CLAIM_STATUS_SUMMARY_FAILURE = 'FETCH_RECTIFICATION_CLAIM_STATUS_SUMMARY_FAILURE';

export const FETCH_TICKET_STATUS_SUMMARY = 'FETCH_TICKET_STATUS_SUMMARY';
export const FETCH_TICKET_STATUS_SUMMARY_SUCCESS = 'FETCH_TICKET_STATUS_SUMMARY_SUCCESS';
export const FETCH_TICKET_STATUS_SUMMARY_FAILURE = 'FETCH_TICKET_STATUS_SUMMARY_FAILURE';

export const SET_CUSTOMER_FILTERS = 'SET_CUSTOMER_FILTERS';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const ADD_CUSTOMER_TO_TICKET = 'ADD_CUSTOMER_TO_TICKET';
export const ADD_CUSTOMER_TO_TICKET_SUCCESS = 'ADD_CUSTOMER_TO_TICKET_SUCCESS';
export const ADD_CUSTOMER_TO_TICKET_FAILURE = 'ADD_CUSTOMER_TO_TICKET_FAILURE';

export const FETCH_STATISTICS = 'FETCH_STATISTICS';

export const FETCH_TICKET_MAP_DATA_SUCCESS = 'FETCH_TICKET_MAP_DATA_SUCCESS';
export const FETCH_TICKET_MAP_DATA_FAILURE = 'FETCH_TICKET_MAP_DATA_FAILURE';
export const SET_TICKET_MAP_FILTER = 'SET_TICKET_MAP_FILTER';

export const FETCH_TICKET_COUNTS_SUCCESS = 'FETCH_TICKET_COUNTS_SUCCESS';
export const FETCH_TICKET_COUNTS_FAILURE = 'FETCH_TICKET_COUNTS_FAILURE';
export const SET_TICKET_COUNTS_FILTER = 'SET_TICKET_COUNTS_FILTER';

export const FETCH_RECOVERY_REPORTS_SUCCESS = 'FETCH_RECOVERY_REPORTS_SUCCESS';
export const FETCH_RECOVERY_REPORTS_FAILURE = 'FETCH_RECOVERY_REPORTS_FAILURE';
export const SET_RECOVERY_REPORTS_FILTER = 'SET_RECOVERY_REPORTS_FILTER';

export const FETCH_RECEIVABLES_SUCCESS = 'FETCH_RECEIVABLES_SUCCESS';
export const FETCH_RECEIVABLES_FAILURE = 'FETCH_RECEIVABLES_FAILURE';
export const SET_RECEIVABLES_FILTER = 'SET_RECEIVABLES_FILTER';
export const FETCH_RECEIVABLES_CSV = 'FETCH_RECEIVABLES_CSV';
export const SET_RECEIVABLES_CSV = 'SET_RECEIVABLES_CSV';

export const FETCH_YEARLY_STATISTICS_SUCCESS = 'FETCH_YEARLY_STATISTICS_SUCCESS';
export const FETCH_YEARLY_STATISTICS_FAILURE = 'FETCH_YEARLY_STATISTICS_FAILURE';
export const SET_YEARLY_STATISTICS_FILTER = 'SET_YEARLY_STATISTICS_FILTER';

export const FETCH_TICKET_REASON_COUNTS_SUCCESS = 'FETCH_TICKET_REASON_COUNTS_SUCCESS';
export const FETCH_TICKET_REASON_COUNTS_FAILURE = 'FETCH_TICKET_REASON_COUNTS_FAILURE';
export const SET_TICKET_REASON_COUNTS_FILTER = 'SET_TICKET_REASON_COUNTS_FILTER';
