import _ from 'lodash';

export const translation = {};
translation.translations = {};
translation.fallbackLanguage = 'fi';
const SUBSTITUTION_MARKER = '{}';

function getTranslationFile(language) {
  return require(`./locale/${language}.json`);
}

translation.initLanguage = function (language) {
  translation.lang = language;
  translation.translations = getTranslationFile(language);
};

export function _tr(obj) {
  if (_.isArray(obj) && obj.length > 0) {
    if (_.isString(obj[0])) {
      let text = _tr(obj[0]);

      let pos = 1;

      while (obj.length > pos && text.indexOf(SUBSTITUTION_MARKER) >= 0) {
        text = text.replace(SUBSTITUTION_MARKER, _tr(obj[pos]));
        pos++;
      }

      // remove remaining substitution markers
      text = text.replace(new RegExp(SUBSTITUTION_MARKER, 'g'), '');
      // remove empty brackets
      text = text.replace(/\(\)/g, '');

      return text;
    }
    return obj.map((val) => _tr(val)).join(', ');
  }

  return translation.translations[obj] || obj;
}
